import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <HeaderImage mdxType="HeaderImage">
      <HeaderMedia id='reise' mdxType="HeaderMedia" />
    </HeaderImage>
    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h1>{`Reisemedizin`}</h1>
      <p>{`Genießen Sie ungetrübte Urlaubstage! Reisefieber ... ist eine der schönsten Krankheiten. Aber nur wenn die Gesundheit mitspielt, kann der Urlaub zum Genuss werden.`}</p>
      <p>{`Wir bieten Ihnen in unserer Praxis eine qualifizierte reisemedizinische Beratung. Wenn Sie eine Reise planen, informieren wir Sie gerne ausführlich über mögliche gesundheitliche Risiken individuell und aktuell auf Ihr persönliches Reiseland und Reiseziel abgestimmt (z. B. Malaria, Hepatitis, Zecken, ...). Wir klären Sie über vorgeschriebene oder empfohlene Schutzimpfungen auf. Neben der Überprüfung der auch bei uns empfohlenen Standardimpfungen (Tetanus, Diphtherie, Kinderlähmung) erhalten Sie einen individuellen Impfplan für Ihr Reiseziel. So minimieren Sie das Risiko, auf Ihrer Reise zu erkranken und können ungetrübte Urlaubstage genießen. Eine richtig sortierte Reiseapotheke gehört in jedem Fall mit in den Koffer. Ich stelle ich Ihnen gerne eine Checkliste zusammen. Sie erhalten dann vorab einen Fragebogen über Urlaubsziel und Reisezeit, damit wir uns vor dem Informationsgespräch darauf vorbereiten können`}</p>
      <p>{`Reisemedizinische Gesundheitsberatung ist nicht im Leistungskatalog der gesetzlichen Krankenkassen enthalten. Wir stellen Ihnen Beratung und Impfungen nach der Gebührenordnung für Ärzte (GOÄ) privat in Rechnung. Mittlerweile erstatten eine Reihe gesetzlicher Krankenkassen jedoch teilweise die Kosten für Impfungen.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
  <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      